<template>
  <base-layout>
    <template v-if="$acl.canView('trips')">
      <div v-if="trip_detail == null" class="h-screen vld-parent">
        <loading :active="trip_detail == null" :is-full-page="false" />
      </div>

      <div v-if="trip_detail != null">
        <TripDetailsScreen
          :trip-detail="trip_detail"
          @download-invoice="onInvoiceModalReq(trip_detail.id, false)"
          @refund="showRefundPopup(trip_detail)"
          @penalize-policy="showPenalizePolicyPopup()"
          @force-completed="fetchDetail"
        />
      </div>

      <!-- <template v-if="vehicleLogs">
      <TimelineForTripDetailsScreen v-model="vehicleLogs" />
    </template> -->

      <InvoiceModal :trip="invoiceModalDetails" :symbol="getCurrencySymbol" />
      <refund-popup
        @refunded="handleRefunding('succeeded')"
        @failed="handleRefunding('failed')"
      />
      <PolicyPenaltyPopup
        :tripId="id"
        :penaltyReasons="penaltyReasons"
        @penalized="fetchDetail"
      />
    </template>
    <div v-else class="py-5 font-bold text-center text-gray-600">
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
  </base-layout>
</template>
<script>
import BaseLayout from '../shared/BaseLayout.vue'
import { TripConfig } from '@/config/TripConfig'

import { TransactionConfig } from '@/config/TransactionConfig'
import { EventBus } from '@/utils/EventBus'
import { resolveProp } from '@/utils'
import { useEndpoints } from '@/composables'

export default {
  components: {
    BaseLayout,
    InvoiceModal: () => import('@/components/modals/InvoiceModal'),
    RefundPopup: () =>
      import('@/composites/transaction/revenue/index/RefundPopup.vue'),
    PolicyPenaltyPopup: () =>
      import('@/composites/transaction/policy-penalty/PolicyPenaltyPopup.vue'),
    TripDetailsScreen: () =>
      import('@/composites/trip/details/TripDetailsScreen'),
    // TimelineForTripDetailsScreen: () =>
    //   import(
    //     '@/components/pages/trip/timeline/TimelineForTripDetailsScreen.vue'
    //   ),
  },
  data() {
    return {
      trip_detail: null,
      showModal: false,
      invoiceModalDetails: {},
      symbol: '$',
      vehicleLogs: null,
      userLogs: null,
      penaltyReasons: [],
    }
  },
  async mounted() {
    await this.fetchDetail(this.id)
    // await this.fetchVehicleLogs(this.id)

    //inbound fetch listener (emitted from TripGoogleMapView)
    window.addEventListener('fetchTripDetail', async (id) => {
      await this.fetchDetail(id)
    })
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getCurrencySymbol() {
      const orgInfo = this.$store.getters['auth/organizationInfo']
      return orgInfo ? orgInfo.default_currency.symbol : '$'
    },
  },
  beforeDestroy() {
    if (this.durationInterval) {
      clearInterval(this.durationInterval)
    }
  },
  methods: {
    async fetchDetail(id) {
      if (!id) id = this.id

      const self = this

      await this.$http
        .get(TripConfig.api.single(id))
        .then((res) => {
          console.log('resEE = ', res.data)
          this.trip_detail = res.data
          this.$store.dispatch('lastTrip/saveStatus', this.trip_detail.status)
          this.$http
            .get(
              useEndpoints.fleet.penaltyIndex(
                this.trip_detail.pricing_plan.fleet.id
              )
            )
            .then((res) => {
              this.penaltyReasons = res.data.data.filter(
                (item) => parseFloat(item.deduct_amount) > 0
              )
            })
          if (self.durationInterval) {
            clearInterval(self.durationInterval)
          }

          if (
            self.trip_detail.status !== 'C' &&
            self.trip_detail.status !== 'T' &&
            self.trip_detail.status !== 'A' &&
            self.trip_detail.drop_off_time === null
          ) {
            self.durationInterval = setInterval(() => {
              if (self.trip_detail) {
                //trip-duration-interval
                self.trip_detail.riding_time += 1

                if (self.trip_detail.child_trips) {
                  for (
                    let i = 0;
                    i < self.trip_detail.child_trips.length;
                    ++i
                  ) {
                    if (
                      self.trip_detail.child_trips[i].status !== 'C' &&
                      self.trip_detail.child_trips[i].status !== 'T' &&
                      self.trip_detail.child_trips[i].status !== 'A' &&
                      self.trip_detail.child_trips[i].drop_off_time === null
                    ) {
                      self.trip_detail.child_trips[i].riding_time += 1
                    }
                  }
                }

                //pause-duration-interval
                if (
                  self.trip_detail.pause_entries.length > 0 &&
                  self.trip_detail.is_paused
                ) {
                  self.trip_detail.pause_time_secs_till_now += 1
                }
              }
            }, 1000)
          }
        })
        .catch((err) => {
          console.log('err = ', err)
        })
    },
    async onInvoiceModalReq(id, downloadOnly = false) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing`,
          text: 'The action is being executed...',
        },
        600
      )

      await this.$http
        .get(this.$config.trip.api.single(id), {
          params: {
            preferred_currency: resolveProp(
              'fsTable.organizationCurrency.selected',
              this.$store.state
            ),
          },
        })
        .then((res) => {
          this.invoiceModalDetails = res.data
          console.log('invoice', this.invoiceModalDetails)

          if (downloadOnly === true) {
            if (this.$refs.invoiceModal) {
              this.$refs.invoiceModal.exportToPDF()
            }
          } else {
            this.$modal.show('invoiceModal')
          }
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },
    showRefundPopup(item) {
      const neededItem = {
        ...item.invoice,
        user: { ...item.user },
      }
      if (item.is_refunded) {
        return
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, neededItem)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    showPenalizePolicyPopup() {
      this.$modal.show(TransactionConfig.events.policyPenaltyPopup)
    },
    async handleRefunding(status = 'failed') {
      if (status === 'succeeded') {
        // await this.getIndexData(this.getQueryString);
        this.fetchDetail(this.id)
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Refunded',
            text: 'Successfully refunded to the rider.',
          },
          3000
        )
        return
      }
    },
    async fetchUserLogs(id) {
      await this.$http
        .get(TripConfig.api.userTimelineLog(id))
        .then((res) => {
          this.$log.debug('userLogs = ', res.data.data)
          this.userLogs = res.data.data
        })
        .catch((err) => {
          console.log('userErr = ', err.response.data)
        })
    },
    async fetchVehicleLogs(id) {
      await this.$http
        .get(TripConfig.api.vehicleTimelineLog(id))
        .then((res) => {
          this.$log.debug('vehicleLogs = ', res.data.data)
          this.vehicleLogs = res.data.data
        })
        .catch((err) => {
          console.log('vehicleErr = ', err.response.data)
        })
    },
  },
}
</script>

<style lang="sass"></style>
