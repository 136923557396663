export const TransactionConfig = {
  api: {
    // get
    index: '/dashboard/invoices/',
    // get
    single: (id = 'uuid') => {
      return `/dashboard/invoices/?trip=${id}`
    },
    // post
    create: '/dashboard/invoices/',
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/invoices/${id}/`
    },
    // post
    refund: (id = 'invoice-uuid') => {
      return `/dashboard/invoices/${id}/refund/`
    },
  },
  events: {
    name: 'invoices',
    // refresh-index-data
    refresh: `rid-trip`,
    // slideover-right
    sorId: 'trip',
    sorOpen: 'sor-open-trip',
    sorClose: 'sor-close-trip',
    sorToggle: 'sor-toggle-trip',
    // editing-data
    editingData: 'edit-trip-data',
    // viewing-data
    viewingData: 'view-transaction-data',
    // refund
    refundPopup: 'refund-popup',
    //policy-penalty
    policyPenaltyPopup: 'policy-penalty-popup',
  },
}
